<template>
  <nav class="navbar navbar-expand-md shadow-sm no-print">
    <div class="container-fluid">
      <router-link class="navbar-brand" :to="{ name: 'Dashboard' }">
        <img src="@/assets/images/Logo.svg" alt="Logo" width="126" />
      </router-link>
      <div class="collapse navbar-collapse justify-content-center">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Dashboard' }">{{
              $t('__dashboard')
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Report' }">{{
              $t('__report')
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'DeviceManagement' }">{{
              $t('__deviceManagement')
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'Contact' }">{{
              $t('__contactUs')
            }}</router-link>
          </li>
        </ul>
      </div>
      <div class="d-flex flex-row">
        <div
          class="sleeptime-setting"
          :class="viewport > breakpoints.sm ? 'dropdown' : ''"
        >
          <button
            class="btn btn-link navbar-icon nav-link"
            type="button"
            ref="sleeptimesettingButton"
            @click="clickSleepingButton"
          >
            <i class="bi bi-moon-fill"></i>
          </button>
          <div
            v-if="viewport > breakpoints.sm"
            class="dropdown-menu dropdown-menu-sm-end"
            style="width: 300px; visibility: initial"
          >
            <form class="px-4 py-3" @submit.prevent="submitForm">
              <label class="form-label fw-medium">{{
                $t('__sleepTimeSetting')
              }}</label>
              <div class="d-flex align-items-center mb-3">
                <VueCtkDateTimePicker
                  :label="$t('__startTime')"
                  v-model="sleeping_time.start_at"
                  only-time
                  format="HH:mm"
                  formatted="HH:mm"
                  right
                  color="#2CBDC0"
                  button-color="#2CBDC0"
                  id="sleeping_time_start_at"
                  :disabled="!hasStatistics"
                />
                <span class="px-1">-</span>
                <VueCtkDateTimePicker
                  :label="$t('__entTime')"
                  v-model="sleeping_time.end_at"
                  only-time
                  format="HH:mm"
                  formatted="HH:mm"
                  right
                  color="#2CBDC0"
                  button-color="#2CBDC0"
                  id="sleeping_time_end_at"
                  :disabled="!hasStatistics"
                />
              </div>
              <div class="text-danger text-center mb-3" v-if="is_invalid">
                {{ feedback_message }}
              </div>
              <div class="row g-2">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-gray rounded-pill w-100"
                    @click="closeSleeptime"
                  >
                    {{ hasStatistics ? $t('__cancel') : $t('__closeWindow') }}
                  </button>
                </div>
                <div class="col" v-if="hasStatistics">
                  <button
                    type="submit"
                    class="btn btn-primary rounded-pill w-100"
                  >
                    {{ $t('__save') }}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            v-if="viewport <= breakpoints.sm"
            class="offcanvas offcanvas-top"
            tabindex="-1"
            ref="sleeptimeSettingOffcanvas"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title fw-medium" id="offcanvasExampleLabel">
                {{ $t('__sleepTimeSetting') }}
              </h5>
              <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <form @submit.prevent="submitForm">
                <div class="d-flex align-items-center mb-3">
                  <VueCtkDateTimePicker
                    :label="$t('__startTime')"
                    v-model="sleeping_time.start_at"
                    only-time
                    format="HH:mm"
                    formatted="HH:mm"
                    right
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    id="mobile_sleeping_time_start_at"
                    :disabled="!hasStatistics"
                  />
                  <span class="px-1">-</span>
                  <VueCtkDateTimePicker
                    :label="$t('__entTime')"
                    v-model="sleeping_time.end_at"
                    only-time
                    format="HH:mm"
                    formatted="HH:mm"
                    right
                    color="#2CBDC0"
                    button-color="#2CBDC0"
                    id="mobile_sleeping_time_end_at"
                    :disabled="!hasStatistics"
                  />
                </div>
                <div class="text-danger text-center mb-3" v-if="is_invalid">
                  {{ feedback_message }}
                </div>
                <button
                  type="submit"
                  class="btn btn-primary w-100 rounded-pill"
                  v-if="hasStatistics"
                >
                  {{ $t('__save') }}
                </button>
                <button
                  v-else
                  type="button"
                  class="btn btn-gray rounded-pill w-100"
                  data-bs-dismiss="offcanvas"
                >
                  {{ hasStatistics ? $t('__cancel') : $t('__closeWindow') }}
                </button>
              </form>
            </div>
          </div>
        </div>
        <button
          class="btn btn-link navbar-icon nav-link d-none d-md-block"
          type="button"
          @click="toggleFullWindow"
        >
          <i
            class="bi"
            :class="
              is_fullWindow
                ? 'bi-arrows-angle-contract'
                : 'bi-arrows-angle-expand'
            "
          ></i>
        </button>
        <button
          class="btn btn-link navbar-icon nav-link"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#notifyOffcanvas"
          aria-controls="notifyOffcanvas"
        >
          <i class="bi bi-bell position-relative">
            <span
              v-show="has_notify"
              class="
                position-absolute
                top-0
                start-100
                translate-middle
                badge
                border border-light
                rounded-circle
                bg-danger
                p-1
              "
              ><span class="visually-hidden">unread messages</span></span
            >
          </i>
        </button>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="notifyOffcanvas">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title fw-medium" id="offcanvasExampleLabel">
              <i class="bi bi-bell position-relative"></i>
              {{ $t('__notify') }}
            </h5>
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body p-0 bg-light">
            <ul
              v-if="withinAnHourNotify && withinAnHourNotify.length"
              class="list-group list-group-flush border-top border-bottom"
            >
              <li
                v-for="(notify, index) in withinAnHourNotify"
                :key="notify.created_at + index"
                class="list-group-item"
                :class="{
                  'list-group-item-primary': timestampFormat(
                    notify.created_at_epoch
                  )
                }"
              >
                <div
                  class="
                    d-flex d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <div class="fs-4 pe-3">
                    <i class="bi bi-exclamation-triangle"></i>
                  </div>
                  <div class="me-auto">
                    <small class="fw-medium">{{
                      notify_type_name[notify.type]
                    }}</small>
                    <small class="fw-medium ps-2">
                      {{ getDeviceInfo(notify.device_id).bed_number }}</small
                    >
                    <p class="mb-0">
                      <span class="fs-4 pe-2 fw-medium">{{
                        getDeviceInfo(notify.device_id).resident_name
                      }}</span>
                      <span class="">
                        {{ notify.type === 'mqttStatus' ? notify_condition_name[notify.message] : notify_condition_name[notify.type] }}
                        <span class="px-1">{{
                          notify.type === 'leaveBed' || notify.type === 'mqttStatus'
                            ? ''
                            : notify.message
                        }}</span>
                        {{ notify_unit[notify.type] }}
                      </span>
                    </p>
                    <small>{{
                      timestampFormat(notify.created_at_epoch)
                        ? timestampFormat(notify.created_at_epoch)
                        : $getTimeZoneDate(
                            notify.created_at_epoch,
                            timezone,
                            'HH:mm:ss'
                          )
                    }}</small>
                  </div>
                </div>
              </li>
            </ul>
            <div v-else class="p-3">
              {{ $t('__notDataToNotify') }}
            </div>
          </div>
        </div>
        <div class="dropdown user-info d-none d-md-block">
          <button
            class="btn btn-link navbar-icon nav-link"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            ref="userInfoDropdown"
          >
            <div>
              {{ userInfo ? userInfo.name.substr(0, 1) : 'H' }}
            </div>
          </button>
          <div class="dropdown-menu dropdown-menu-end" style="width: 260px">
            <div class="px-4 py-3 text-center" v-if="userInfo">
              <div class="fw-medium">{{ userInfo.name }}</div>
              <small class="text-dark">{{ userInfo.username }}</small>
              <div class="dropdown-divider"></div>
              <div class="fw-medium">{{ userInfo.agency.name }}</div>
              <small class="text-dark">{{
                $t('__agent') + ': ' + userInfo.agency.manager.name
              }}</small>
              <div class="dropdown-divider"></div>
              <button
                class="btn btn-primary rounded-pill w-100 mt-2 text-white"
                style="background-color: #06c755"
                @click="LINENotifyAuthorize"
              >
                <small>{{ $t('__LINENotifyAuthorize') }}</small>
              </button>
              <small
                class="text-center d-block"
                v-if="LINEAuth && LINEAuth.length"
                >{{
                  $t('__LINENotifyAuthorizedQuantity', { qty: LINEAuth.length })
                }}</small
              >
              <small class="text-center d-block" v-else>{{
                $t('__LINENotifyAuthorizeNoAccount')
              }}</small>
              <button
                type="button"
                class="btn btn-primary rounded-pill w-100 mt-3 mb-2"
                @click="signOut"
              >
                {{ $t('__signOut') }}
              </button>
              <div class="dropdown-divider"></div>
              <div>
                <small class="text-dark">
                  <span class="fw-medium">{{ $t('__language') }}：</span>
                  {{ $t(`__${userInfo.agency.locale}`) }}
                </small>
              </div>
              <div>
                <small class="text-dark">
                  <span class="fw-medium">{{ $t('__timeZone') }}：</span>
                  {{ timezone + ' ' + timeZoneOffset }}
                </small>
              </div>
              <small class="pt-3 d-block"
                >©
                {{ new Date().getFullYear() + ' ' + $t('__HumetricsInc') }} All
                rights reserved.</small
              >
            </div>
          </div>
        </div>
        <button
          class="navbar-toggler text-dark"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#navbarOffcanvas"
          aria-controls="navbarOffcanvas"
        >
          <i class="bi bi-list"></i>
        </button>
        <div
          class="offcanvas offcanvas-end bg-light"
          tabindex="-1"
          id="navbarOffcanvas"
          ref="navbarOffcanvas"
        >
          <div class="offcanvas-header pb-0">
            <button
              type="button"
              class="btn-close btn-close-white text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="offcanvas-body d-flex flex-column justify-content-between"
          >
            <div class="text-white mb-5 mx-3" v-if="userInfo">
              <div class="fw-medium fs-5">
                {{ userInfo.name }}
                <small>{{ userInfo.username }}</small>
              </div>
              <div class="mt-2">{{ userInfo.agency.name }}</div>
              <small>{{
                $t('__agent') + '：' + userInfo.agency.manager.name
              }}</small>
            </div>
            <ul class="navbar-nav mb-auto mt-4">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'Dashboard' }"
                  data-bs-dismiss="offcanvas"
                  >{{ $t('__dashboard') }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'Report' }"
                  data-bs-dismiss="offcanvas"
                  >{{ $t('__report') }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'DeviceManagement' }"
                  data-bs-dismiss="offcanvas"
                  >{{ $t('__deviceManagement') }}</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'Contact' }"
                  data-bs-dismiss="offcanvas"
                  >{{ $t('__contactUs') }}</router-link
                >
              </li>
            </ul>
            <div class="m-3">
              <button
                class="btn btn-primary rounded-pill w-100 mt-3 text-white"
                style="background-color: #06c755"
                @click="LINENotifyAuthorize"
              >
                <small>{{ $t('__LINENotifyAuthorize') }}</small>
              </button>
              <small
                class="text-center d-block"
                v-if="LINEAuth && LINEAuth.length"
                >{{
                  $t('__LINENotifyAuthorizedQuantity', { qty: LINEAuth.length })
                }}</small
              >
              <small class="text-center d-block" v-else>{{
                $t('__LINENotifyAuthorizeNoAccount')
              }}</small>
              <button
                type="button"
                class="btn btn-primary rounded-pill w-100 mt-3 mb-3"
                @click="signOut"
              >
                {{ $t('__signOut') }}
              </button>
              <div class="text-center" v-if="userInfo">
                <small class="text-dark">
                  <span class="fw-medium">{{ $t('__language') }}：</span>
                  {{ $t(`__${userInfo.agency.locale}`) }}
                </small>
              </div>
              <div class="text-center">
                <small class="text-dark">
                  <span class="fw-medium">{{ $t('__timeZone') }}：</span>
                  {{ timezone + ' ' + timeZoneOffset }}
                </small>
              </div>
              <small class="pt-3 d-block text-center"
                >©
                {{ new Date().getFullYear() + ' ' + $t('__HumetricsInc') }} All
                rights reserved.</small
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <audio src="" id='audio_ctrl'></audio>
  </nav>
</template>

<script>
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import i18n from '@/lang/lang.js'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import { Dropdown, Offcanvas } from 'bootstrap'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { dashboard } from '@/http/api/dashboard.js'
import { line } from '@/http/api/line.js'
import audioNotify from '@/assets/notify.mp3'

export default {
  name: 'Navbar',
  components: { VueCtkDateTimePicker },
  data () {
    return {
      sleeping_time: {
        start_at: null,
        end_at: null
      },
      has_notify: false,
      is_fullWindow: false,
      is_invalid: false,
      feedback_message: null,
      bootstrap_dropdown: null,
      bootstrap_offcanvas_sleeptime: null,
      bootstrap_offcanvas_navbar: null,
      LINEAuth: null,
      oldNotifyTime: null
    }
  },
  computed: {
    ...mapState([
      'viewport',
      'token',
      'notifyList',
      'breakpoints',
      'userInfo',
      'updateCycle',
      'hasStatistics',
      'timezone'
    ]),
    ...mapGetters([
      'sortNotify',
      'timeZoneOffset',
      'notify_type_name',
      'notify_condition_name',
      'notify_unit'
    ]),
    withinAnHourNotify: function () {
      const vm = this
      if (!vm.sortNotify) return ''
      var withinAnHourNotify = []
      vm.sortNotify.forEach(notify => {
        if (
          Math.floor(new Date() - new Date(notify.created_at)) <=
          1000 * 60 * 60
        ) {
          withinAnHourNotify.push(notify)
        }
      })
      return withinAnHourNotify
    }
  },
  watch: {
    userInfo () {
      const vm = this
      vm.getSleepingTime()
      vm.getNotification()
      vm.initBootstrapComponents()
      vm.getLINEAuth()
      window.document.title =
        i18n.t(vm.$route.meta.title) + ' | ' + i18n.t('__humetrics')
      document.documentElement.lang = i18n.locale
    },
    sleeping_time: {
      handler: function () {
        this.is_invalid = false
      },
      deep: true
    },
    viewport () {
      const vm = this
      vm.initBootstrapComponents()
    },
    sortNotify (data) {
      const vm = this
      const nowTime = new Date()
      if (!vm.oldNotifyTime) {
        vm.oldNotifyTime = data[0].created_at
      }
      if (vm.oldNotifyTime !== data[0].created_at) {
        vm.oldNotifyTime = data[0].created_at
        vm.playNotifyAudio()
        vm.AlertsInfo({
          state: 'error',
          title: i18n.t('__notify'),
          info: i18n.t('__notifyHasNew')
        })
        vm.Alerted()
      }
      if (Math.floor(nowTime - new Date(data[0].created_at)) <= 1000 * 60 * 3) {
        vm.has_notify = true
      } else {
        vm.has_notify = false
      }
    }
  },
  methods: {
    ...mapMutations(['Loading', 'Loaded', 'AlertsInfo', 'Alerted']),
    ...mapActions(['getNotification', 'getUserInfo']),
    playNotifyAudio () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        const audio = document.getElementById('audio_ctrl')
        audio.src = audioNotify
        audio.muted = false
        audio.play()
      } else {
        const audio = document.createElement('audio')
        audio.src = audioNotify
        audio.play()
      }
    },
    initAudio () {
      const audio = document.getElementById('audio_ctrl')
      audio.src = audioNotify
      audio.muted = true
      audio.play()
      window.removeEventListener('touchstart', this.initAudio)
    },
    signOut () {
      localStorage.removeItem('humetrics_user_token')
      this.$router.push({ name: 'Login' })
    },
    getDeviceInfo (id) {
      const vm = this
      let deviceInfo = null
      vm.notifyList.forEach(notify => {
        if (notify.id === id) {
          deviceInfo = notify
        }
      })
      return deviceInfo
    },
    format (date, formatText) {
      return new Date(date).format(formatText)
    },
    timestampFormat (unixTime) {
      // 將時間轉換顯示方式

      const timestamp = unixTime * 1000
      const minute = 1000 * 60
      const hour = minute * 60
      const day = hour * 24
      const month = day * 30
      const year = day * 365
      const now = new Date().getTime()
      const diffValue = now - timestamp
      const yearC = diffValue / year
      const monthC = diffValue / month
      const weekC = diffValue / (7 * day)
      const dayC = diffValue / day
      const hourC = diffValue / hour
      const minC = diffValue / minute

      if (yearC >= 1) {
        return null
      } else if (monthC >= 1) {
        return null
      } else if (weekC >= 1) {
        return null
      } else if (dayC >= 1) {
        return null
      } else if (hourC >= 1) {
        return null
      } else if (minC > 3) {
        return null
      } else if (minC >= 1) {
        return parseInt(minC) + i18n.t('__minAgo') // XX分鐘內
      } else {
        return i18n.t('__justNew') // 剛剛
      }
    },
    initBootstrapComponents () {
      this.$nextTick(function () {
        const vm = this
        if (vm.bootstrap_dropdown) vm.bootstrap_dropdown.dispose()
        if (vm.bootstrap_offcanvas_sleeptime) {
          vm.bootstrap_offcanvas_sleeptime = null
        }
        if (vm.viewport <= 576) {
          vm.bootstrap_offcanvas_sleeptime = new Offcanvas(
            vm.$refs.sleeptimeSettingOffcanvas
          )
        } else {
          vm.bootstrap_dropdown = new Dropdown(
            vm.$refs.sleeptimesettingButton,
            {
              autoClose: false
            }
          )
        }
      })
    },
    fullWindow () {
      const docElm = document.documentElement
      if (docElm.requestFullscreen) {
        // W3C
        docElm.requestFullscreen()
      } else if (docElm.mozRequestFullScreen) {
        // FireFox
        docElm.mozRequestFullScreen()
      } else if (docElm.webkitRequestFullScreen) {
        // Chrome
        docElm.webkitRequestFullScreen()
      } else if (docElm.msRequestFullscreen) {
        // IE11
        docElm.msRequestFullscreen()
      }
    },
    cancelFullWindow () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
    toggleFullWindow () {
      const vm = this
      vm.is_fullWindow = !vm.is_fullWindow
      vm.is_fullWindow ? vm.fullWindow() : vm.cancelFullWindow()
    },
    clickSleepingButton () {
      const vm = this
      vm.$store.dispatch('getStatistics').then(() => {
        // if (vm.hasStatistics) {
        // }
      })
      if (Number(vm.viewport) === vm.breakpoints.sm) {
        vm.bootstrap_offcanvas_sleeptime.show()
      }
    },
    getSleepingTime () {
      const vm = this
      dashboard
        .getSleepingTime(vm.token)
        .then(res => {
          if (res.status <= 201) {
            const data = res.data.data
            vm.sleeping_time.start_at = data.start
            vm.sleeping_time.end_at = data.end
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
        })
        .catch(err => {
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: err
          })
          vm.Alerted()
        })
    },
    checkForm () {
      const vm = this
      vm.feedback_message = i18n.t('__sleepTimeIsRequired')
      if (!vm.sleeping_time.start_at) {
        vm.is_invalid = true
      } else if (!vm.sleeping_time.end_at) {
        vm.is_invalid = true
      } else {
        vm.is_invalid = false
        vm.feedback_message = null
      }
    },
    closeSleeptime () {
      const vm = this
      if (vm.bootstrap_dropdown) vm.bootstrap_dropdown.hide()
    },
    submitForm () {
      const vm = this
      vm.checkForm()
      if (vm.is_invalid) return
      if (vm.bootstrap_dropdown) vm.bootstrap_dropdown.hide()
      if (vm.bootstrap_offcanvas_sleeptime) {
        vm.bootstrap_offcanvas_sleeptime.hide()
      }
      vm.Loading()
      dashboard
        .updateSleepingTime(
          {
            start: vm.sleeping_time.start_at,
            end: vm.sleeping_time.end_at
          },
          vm.token
        )
        .then(res => {
          vm.Loaded()
          if (res.status <= 201) {
            vm.AlertsInfo({
              state: 'success',
              title: i18n.t('__success'),
              info: i18n.t('__sleepTimeUpdatedSuccessfully')
            })
            vm.Alerted()
            vm.getSleepingTime()
          } else {
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errMsg.toString()
            })
            vm.Alerted()
          }
        })
        .catch(error => {
          vm.Loaded()
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: error
          })
          vm.Alerted()
        })
    },
    getLINEAuth () {
      const vm = this
      line.lineAuth(vm.token).then(res => {
        if (res.status <= 201 && res.data.status === 'success') {
          vm.LINEAuth = res.data.data
        } else {
          vm.Loaded()
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: res.data.errors.toString()
          })
          vm.Alerted()
        }
      })
    },
    LINENotifyAuthorize () {
      const vm = this
      vm.Loading()
      line
        .lineOauth(vm.token)
        .then(res => {
          if (res.status <= 201) {
            vm.Loaded()
            window.open(res.data.authLink)
          } else {
            vm.Loaded()
            vm.AlertsInfo({
              state: 'error',
              title: i18n.t('__error'),
              info: res.data.errors.toString()
            })
            vm.Alerted()
          }
        })
        .catch(error => {
          vm.Loaded()
          vm.AlertsInfo({
            state: 'error',
            title: i18n.t('__error'),
            info: error
          })
          vm.Alerted()
        })
    }
  },
  created () {
    const vm = this
    vm.getUserInfo()
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      window.addEventListener('touchstart', this.initAudio)
    }
  },
  mounted () {
    const vm = this
    document.querySelector('body').style.cssText = ''
    vm.$refs.userInfoDropdown.addEventListener('show.bs.dropdown', function () {
      vm.getLINEAuth()
    })
    vm.$refs.navbarOffcanvas.addEventListener('show.bs.offcanvas', function () {
      vm.getLINEAuth()
    })
    const timer = setInterval(() => {
      if (vm.userInfo) {
        vm.getNotification()
      }
    }, vm.updateCycle)
    vm.$once('hook:beforeDestroy', () => {
      clearInterval(timer)
    })
  }
}
</script>

<style lang="scss">
.navbar {
  background-color: $white;
  a,
  &-icon {
    color: $dark;
  }
  .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    &:hover,
    &:focus {
      color: $primary;
    }
    &.btn {
      &:focus {
        box-shadow: none;
      }
    }
  }
  .navbar-collapse {
    .nav-link {
      color: rgba($dark, 0.5);
      padding-right: 2rem;
      padding-left: 2rem;
      @include media-breakpoint-down(lg) {
        padding-right: 1rem;
        padding-left: 1rem;
      }
      &.active {
        color: $dark;
        position: relative;
        &::after {
          content: '';
          width: 100%;
          height: 0.3rem;
          background: $linear-gradient;
          position: absolute;
          top: calc(-0.5rem - 1px);
          left: 0;
        }
      }
      &:hover {
        color: $dark;
      }
    }
  }
  .user-info {
    > .btn {
      padding: 0;
      line-height: 42px;
      div {
        color: $white;
        z-index: 1;
        position: relative;
        width: 30px;
        &::after {
          content: '';
          width: 100%;
          height: 0;
          padding-top: 100%;
          background: $linear-gradient;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
      }
    }
  }
}
#navbarOffcanvas {
  overflow: hidden;
  .offcanvas-header {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    z-index: 1;
  }
  .offcanvas-body {
    overflow-x: hidden;
    position: relative;
    padding: 32px 0 0 0;
    &::before {
      content: '';
      width: 50%;
      height: 0;
      padding-top: 50%;
      position: absolute;
      top: 0;
      right: 0;
      background-image: url('~@/assets/images/icon-white.svg');
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.3;
      transform: translateY(-20%);
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 150%;
      height: 0;
      padding-top: 370px;
      background: $linear-gradient;
      border-radius: 0 35%;
      z-index: -1;
      transform: translate(-15%, -219px) rotate(345deg);
    }
  }
  .nav-link {
    padding: 1rem 1rem;
    color: rgba($dark, 0.7);
    font-weight: 500;
    &.active {
      color: $dark;
      background-color: $white;
      position: relative;
      &::after {
        content: '';
        width: 0.3rem;
        height: 100%;
        background: $linear-gradient;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
#notifyOffcanvas {
  .dropdown {
    .btn-link {
      &:focus {
        box-shadow: none;
      }
    }
  }
}
</style>
